import React from "react";

// components
import DownForMaintenanceIcon from "@/components/icons/DownForMaintenanceIcon";
import Button from "@/shared/Button";


const DownForMaintenance = ({ userType }: { userType?: string }) => {

  const getButtonRedirectLink = () => {
    switch (userType) {
      case "hirer":
        return "/go-search";
      case "landlord":
        return "/locations";
      default:
        return "/";
    }
  }

  return (
    <div className="locl-down-for-maintenance" data-testid="locl-down-for-maintenance">
      <div className="locl-down-for-maintenance__icon">
        <DownForMaintenanceIcon/>
      </div>
      <div className="locl-down-for-maintenance__page-content">
        <h1 className="locl-down-for-maintenance__page-content__title">
          We'll be back
        </h1>
        <p className="locl-down-for-maintenance__page-content__description">
          Our site is temporarily unavailable. We'll have things up and running again in a flash!
        </p>
        <div className="locl-down-for-maintenance__page-content__buttons">
          <a href={getButtonRedirectLink()}>
            <Button type="secondary">
              Return
            </Button>
          </a>
          <Button type="primary" onClick={() => window.location.reload()}>
            Retry
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DownForMaintenance;
