import * as Sentry from "@sentry/nextjs";


export const reportAppGlobalError = (error: Error) => {
  if (!sessionStorage.getItem("app-crashed") && error.name === "ChunkLoadError") {
    sessionStorage.setItem("app-crashed", "true");
    window.location.reload();
  } else {
    Sentry.captureException(error, { tags: { "app-crashed": "true" } });
  }
};
