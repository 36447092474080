"use client";

import { useEffect } from "react";

// components
import DownForMaintenance from "@/containers/DownForMaintenance";

// helpers
import { reportAppGlobalError } from "@/helpers/reportAppGlobalError";


export default function GlobalError({ error }: { error: Error & { digest?: string } }) {
  useEffect(() => {
    reportAppGlobalError(error);
  }, [error]);

  return (
    <html>
      <body>
        <DownForMaintenance/>
      </body>
    </html>
  );
}
